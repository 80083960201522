.number-of-guests-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="radio"] {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1;
  width: 1;
  margin: -1;
  padding: 0;
  border: 0;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  width: fit-content;
  height: 3rem;
  color: #314D69;
  padding: 0.5rem;
  overflow: hidden;
  border: 1px solid #314D69;
  background-color: #fff;
  border-radius: 10px;
  font-family: 'Inter', 'Source Sans Pro', sans-serif;
  margin: 6px 3px;
}

.icon:hover {
  cursor: pointer;
  background-color: #314D69;
  color: white;
}

.peer:checked ~ .icon, .icon:active {
  background-color: #314D69;
  color: white;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 0;
  gap: 0.5rem;
  margin-bottom: 0.5em;
}

.wrapper label{
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-width: 15rem;
}

.align-center-with-margin{
  text-align: center;
  margin: 3em 0;
  font-family: 'Inter', 'Source Sans Pro', sans-serif;
  color: #173655;
  font-weight: 500;
  font-size: 0.9em;
}

.align-center-with-margin a{
  line-height: 1.5em;
  color: #173655;
  font-weight: 500;
}