.swal-title {
    margin: 0px;
    color: #173655;
    margin-top: 0.5em !important;
    font-family: 'Inter', 'Source Sans Pro', sans-serif;
    margin-bottom: 28px;
    text-align: start;
    padding-left: 1.5em;
    padding-top: 1em;
    font-size: 18px;
    font-weight: 900;
  }

  .swal-text{
    font-family: 'Inter', 'Source Sans Pro', sans-serif;
    color: #173655;
    display: block;
    padding-left: 2em;
  }

  .swal-overlay {
    background-color: rgba(237,242,247, 0.6);
  }

  .swal-modal {
    background-color: white;
    border: 0px;
    border-radius: 15px;
  }

  .swal-button{
    border-radius: 10px;
    font-family: 'Inter', 'Source Sans Pro', sans-serif;
  }

  .swal-button--confirm{
    background-color: #173655 !important;
  }

  .swal-button--cancel{
    color: #173655 !important;
    background-color: white;
    border: 1px solid #173655;
  }

  .swal-footer{
    margin-top: 10px;
  }