.react-calendar {
  font-family: "Inter", "Source Sans Pro", sans-serif !important;
  border: 0 !important;
  background-color: #F7FAFC !important;
}

.react-calendar button {
  min-width: 3rem;
  width: fit-content;
  height: 3rem;
  color: #314D69;
  padding: 0.5rem;
  overflow: hidden;
}

.react-calendar button:disabled,
.react-calendar button[disabled] {
  color: #e3e3e3;
  background-color: #F7FAFC;
}

.react-calendar__navigation__label {
  text-transform: uppercase;
  font-size: 1.2em;
}

.react-calendar abbr {
  text-decoration: none;
}

.react-calendar__tile--now {
  color: #314D69 !important;
  background-color: #E6E6E6 !important;
  border-radius: 10px;
}

.react-calendar-flex {
  flex: 0 0 14.2857%;
  overflow: hidden;
}

.react-calendar__month-view__weekdays{
  color: #314D69 !important;
}

.react-calendar__month-view__days__day{
  border-radius: 10px;
}

.react-calendar__month-view__days__day:enabled:hover{
  background-color: #314D69 !important;
  color: white !important;
}