.final-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
    text-align: center;
    color: #173655;
}

.final-container p:first-child {
    font-weight: bold;
  }
  