.flex-wrap{
    display: flex;
    flex-wrap: wrap;
}

.flex-grow-1{
    flex-grow: 1;
}

.flex-direction-column{
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}

.flex-no-wrap{
    display: flex;
    flex-wrap: nowrap;
}

.flex-align-left{
    display: flex;
    align-items: flex-end;
}

.flex-glow-width-100{
    flex-grow: 1;
    width: 100%;
}

.flex-direction-column{
    display: flex;
    flex-direction: column;
}