a {
  color: #214D7A;
  text-decoration: none;
}
.container {
  max-width: 100%;
  margin: auto;
}
.nav {
  position: relative;
  background-color: #EDF2F7;
}

.nav img{
  height: 1em;
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  position: relative;
  z-index: 9;
  color: #214D7A;
}
.item-left img {
  cursor: pointer;
  padding: 4px 8px;
}
.item-left,
.item-right {
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 0 0 17.5%;
}
.item-center {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.item-right .menu-list {
  justify-content: end;
}
.menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  font-weight: 500;
}
.menu-list li {
  padding: 4px 8px;
}
.menu-list li a {
  align-items: center;
  display: inline-flex;
  margin: 0 8px;
  min-block-size: 24px;
}
.menu-backdrop {
  position: absolute;
  top: var(--block-top);
  left: var(--block-left);
  height: var(--block-height);
  width: var(--block-width);
  top: 50%;
  border-radius: 4px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);

  opacity: 0;
  visibility: hidden;

  transition: 0.5s cubic-bezier(0.75, 0, 0, 1);
}
