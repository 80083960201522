.activity-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin: auto;
  max-width: 620px;
  padding: 0 5%;
  margin-bottom: 1em;
}

.activity {
  border-radius: 16px;
  box-sizing: border-box;
  /*box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);*/
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-items: center;
  max-width: 22rem;
  padding: 1rem;
  text-align: center;
  color: #173655;
  margin-bottom: 1em;
}

.activity-title {
  margin: 1.25rem 0 0.75rem 0;
}

.activity-subtitle {
  color: gray;
  margin: 0.5rem 0 0 0;
}

.activity-text {
  margin: 0.5em 0;
  font-size: 0.8em;
  width: 80%;
}

.activity-footer {
  display: flex;
}

.activity-footer {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0.5em;
  height: 56px;
}

.activity-button {
  border: none;
  border-radius: 16px;
  color: white;
  background-color: #314D69;
  cursor: pointer;
  padding: 0.75rem 1.75rem;
  font-weight: bold;
  font-size: 0.7em;
}


.activity-image{
  border-radius: 8px;
  overflow: hidden;
  width: 90%;
  margin-top: 0.75em;
  height: auto;
}

.activity-image img{
  width: 100%;
  height: 100%;
}

.activity-video{
  font-size: 0.8em;
}