.flex-center{
    display: flex;
    justify-content: center;
}

.details-container {
    max-width: 25rem;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #173655;
}