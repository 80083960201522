.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  top: 22px;
  z-index: 1;
}

.dropdown-content[data-show=true] {
  display: block;
}

.dropdown-content {
  margin: 0;
  padding: 0;
}

.dropdown-content li {
  color: black;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  list-style-type: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.dropdown-content li:hover {
  background-color: #f1f1f1;
}

.dropdown-button {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  margin: 0;
  outline: none;
  padding: 0;
}

.dropdown-button span[data-show=true] {
  transform: rotate(180deg);
}